import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { PortfolioPage, Summary, Review, Aside, Notes } from "../../../components/mdx/portfolio.js";
import { Border } from "../../../components/widgets/container";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PortfolioPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{props.pageContext.frontmatter.author} {props.pageContext.frontmatter.title}</h1>
    <Summary mdxType="Summary">
      <Aside mdxType="Aside">
        <p><strong parentName="p">{`Similar Portfolios`}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/?tag=private"
            }}>{`All Ridgeline strategies`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/?tag=private"
            }}>{`All private strategies`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/priv-cook-multi-mom-etf/"
            }}>{`Multi-Period Momentum`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/priv-cook-multi-mom-etf-factors/"
            }}>{`Multi-Period Momentum (factor variant)`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/priv-cook-multi-mom-etf-bonds/"
            }}>{`Multi-Period Momentum (bond variant)`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/priv-cook-multi-mom-etf-commodities/"
            }}>{`Multi-Period Momentum (commodity variant)`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/priv-cook-multi-mom-etf-cash/"
            }}>{`Multi-Period Momentum (cash variant)`}</a></li>
        </ul>
      </Aside>
      <Border mdxType="Border">
        <p>{`This is a private strategy for T. Cook @ Ridgeline Asset Management. Access to this page is by invitation only.`}</p>
      </Border>
      <ul>
        <li parentName="ul">{`Objective: aggressive growth`}</li>
        <li parentName="ul">{`Type: momentum`}</li>
        <li parentName="ul">{`Asset classes: industry sector stock ETFs`}</li>
        <li parentName="ul">{`Rebalancing schedule: daily`}</li>
        <li parentName="ul">{`Taxation: 90% short-term capital gains`}</li>
        <li parentName="ul">{`Minimum account size: $2,000`}</li>
      </ul>
    </Summary>
    <Review mdxType="Review">
      <h2>{`Strategy Rules & Schedule`}</h2>
      <Aside mdxType="Aside">
        <p><a parentName="p" {...{
            "href": "https://www.bertram.solutions/research-development/"
          }}><span parentName="a" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "195px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "23.076923076923077%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABZUlEQVQY0z2PzytEcRTF7zOSRsRQM8qIFIs3zH1hQmnMfB+SjcX4UVZSlIS74pk7iIVJw9IGkY1/gY143/kHlJWtSPO+C2Rl8/QeOXU6t7v4dA4IkuuCZDRNxdAw3evDdN+bpmKdIBlKLl03z564kcqq0EAwADoAQFckqDmM4OwYoBg1hw143BiCt2w3PGcTAILkuSB5JkhuCpKWIJkXJNcEydP06t3BmPVQqKgOXwBAc0sQfHkQD6Y8MBvwvd0OT9YgfG3pPrBVkOwwyW7wWgmSEUF2U2rlNjZ/6dY24rRRUV525B736/aiHnzhvoDDRoMHU4zhEhu1H7kYfOZi8J7r9IG+TbL/b0E2zF+5APWdXqFJs61m1y0k9l6tOCvGPcXo5ZpiLCjGvGLc/vsdQJqKmiCpmWT76Tm1fKO1DiwEfgfC1OF4dNTd7xkrZeMzinFEMc44jBmHcUgxZhTjhGJMKsa5H9hpl+GAr6DYAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="span" {...{
                "className": "gatsby-resp-image-image",
                "alt": "besol",
                "title": "besol",
                "src": "/static/791b25e89ba768f3e3112dd373235302/cb9a0/besol.png",
                "srcSet": ["/static/791b25e89ba768f3e3112dd373235302/cb9a0/besol.png 195w"],
                "sizes": "(max-width: 195px) 100vw, 195px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
    `}</span></a></p>
        <p><strong parentName="p">{` R&D Reports `}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "2022viiii11-%2013%20r&d.pdf"
            }}>{`R&D report #13`}</a></li>
        </ul>
      </Aside>
      <p>{`This strategy uses a novel approach to determine asset momentum. Unlike traditional strategies, which measure momentum over a fixed lookback period, or `}<a parentName="p" {...{
          "href": "/portfolios/tt-round-robin/"
        }}>{`TuringTrader's Round Robin`}</a>{`, which uses walk-forward-optimization to adjust its parameters, this strategy adjusts the lookback period based on the asset's volatility.`}</p>
    </Review>
    <Notes mdxType="Notes">
      <p><strong parentName="p">{`Portfolio Revisions`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "./?v=1"
          }}>{`v1, September 2022`}</a>{`: Initial release based on proprietary research.`}</li>
      </ul>
    </Notes>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      